/* Day number button */
.rbc-button-link {
    font-size: 1.5rem;
    width: 101.8%;
    height: 100%;
    color: rgba(0,0,0,0.2);
}
.rbc-button-link:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,0.1);
    transition: 0.2s ease-in-out;
}
/* Day Number Row */

.rbc-toolbar-label{
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba(0,0,0,0.9);
    background-color: #035e3f;
    border-radius: 10px;
    margin: 20px 40px;
    color: whitesmoke;
}
/* Day cell */
.rbc-date-cell{
    width: 100%;
    height: calc(78vh/5);
    border: 1px solid rgba(0, 0, 0, 0.5);
    @media (max-width: 768px) {
        height: calc(85vh/8) !important;
    }
}
.rbc-month-view{
    height: 100%;
}

/* calendar container */
.rbc-calendar{
    font-family: sans-serif;
    height: 85vh !important;
    @media (max-width: 768px) {
        height: 85vh !important;
    }
}
/* off range date cell */
.rbc-off-range{
    background-color: rgba(0,0,0,0.3);
}

/* HEADER */
/* header (day names and so) */
/* header day cell */
.rbc-header{
    font-size: 1.5rem;
    border: 1px solid rgba(0,0,0,0.3);
    width: calc(98%/7);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #035e3f;
    padding: 3px 0 3px 0;
    color: whitesmoke;
    @media (max-width: 768px) {
        font-size: 16px !important;
    }
}
/* TOOLBAR */
.rbc-toolbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px;
    
    
}

.rbc-btn-group:last-of-type button {
    border-radius: 5px;
    font-size: 1.2rem;
    padding: 2px 4px 2px 4px;
    width: 100px;
    @media (max-width: 768px) {
        font-size: 16px !important;
    }
  }
  .rbc-btn-group:last-of-type button:hover{
    background-color: rgba(0,0,0,0.2);
    transition: 0.2s ease-in-out;
    color: whitesmoke;
    background-color: #035e3f;
    cursor: pointer;
  }
.rbc-btn-group:first-of-type button {
    padding: 2px 4px 2px 4px !important;
    font-size: 1.2rem !important;
    border-radius: 5px;
    padding: 4px 4px 4px 4px;
    width: 100px;
    @media (max-width: 768px) {
        font-size: 16px !important;
    }
  }
  .rbc-btn-group:first-of-type button:first-child {
    font-size: 1.2rem;
    border-radius: 5px;
    background-color: #011c13;
    border: 0.5px solid rgba(0,0,0,0.3);
    color: whitesmoke;
    padding: 4px 4px 4px 4px;
    width: 80px;

  }
  .rbc-btn-group:first-of-type button:hover{
    transition: 0.2s ease-in-out;
    background-color: rgba(3,94,63,0.8);
    color: whitesmoke;
    cursor: pointer;
  }

  

.rbc-active{
    background-color: rgba(3,94,63,0.8);
    color: whitesmoke;
    border: black 1px solid;
}
.rbc-now{
    background-color: #011c13;
    color: whitesmoke;
    transition: 0.2s ease-in-out;
}
.rbc-label,rbc-now{
    background-color: transparent;
    color: whitesmoke;
    transition: 0.2s ease-in-out;
    display: flex  !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 1.2rem !important;
    
}

/* EVENTS */

.rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment .rbc-event {
    width: 20px !important;
    height: 20px !important;
    left: 1%;
    position: relative;
    background-color: #035e3f !important;
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    opacity: 0.3;
    transition: 0.2s ease-in-out;
    border-radius: 50%;
  }
  .rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment{
    z-index: -1;
  }
  .rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment .rbc-event:hover{
    opacity: 1 ;
  }

.rbc-row:first-of-type{
    height: 5%;
}
.rbc-show-more{
    color: green !important;
    opacity: 0.6 !important;
}
.rbc-show-more:hover{
    color: green !important;
    opacity: 1 !important;
    background-color: transparent;
}
.event-content-div{
    height: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;   
    align-items: center !important;
}
.rbc-event-content{    
    color: whitesmoke !important;
    width: calc(100% - 5px) !important;
}

/* DAYVIEW */
/* times */
.rbc-time-content{
    margin-top: 30px;
    color: black;
    height: 65vh;
    position: relative;
}

.rbc-timeslot-group{
    border: 1px solid rgba(0,0,0,0.3);
    border-bottom: none;
    width: 100%;
    height: calc(65vh/12);
}
.rbc-timeslot-group:last-child{
    border-bottom: 1px solid rgba(0,0,0,0.3);
}
.rbc-row-content{
height: 20px !important;
}

.rbc-day-slot{
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
} 

.rbc-day-slot .rbc-events-container .rbc-event{
    background-color: #035e3f;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    border:none;
    transform: scaleX(0.95);
    @media (max-width: 768px) {
        font-size: 10px !important;
    }
}

/* AGENDA */
.rbc-agenda-view{
    width: 100%;
    border: #035e3f 1px solid;
    height: 70vh;
    margin-top:20px;
}
.rbc-agenda-table{
    width: 100%;
    text-align: center;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
   width: 33% !important;
   padding: 10px 0 10px 0 !important;
   text-align: center !important;
   font-size: 1.1rem !important;
  }
  .rbc-agenda-view table.rbc-agenda-table thead > tr{
    display: flex !important;
  }
.rbc-agenda-date-cell{
    width: 33% !important;
    border: 1px solid rgba(0,0,0,0.3) !important;
}
.rbc-agenda-time-cell{
    width: 33% !important;
}
.rbc-agenda-event-cell{
    width: 33% !important;
}
.rbc-time-slot{
    border: #035e3f 1px solid;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-end !important;
}
.rbc-time-gutter{
    background-color: #035e3f;
    color: whitesmoke;
    font-weight: 600;
    display: flex;
    
}